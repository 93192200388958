<template>
  <CBreadcrumb style="--cui-breadcrumb-divider: '>'">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from "vue";
import router from "@/router";

export default {
  name: "AppBreadcrumb",
  setup() {
    const breadcrumbs = ref();

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        const arrPath = route.path.split("/");
        const arrCurrentPath = router.currentRoute.value.fullPath.split("/");
        const checkingSemicolon = route.path.split(':');
        //console.log(checkingSemicolon);
        // !!checkingSemicolon[1] ? `${route.name} / ${arrCurrentPath[3]}` : route.name
        return {
          active: arrPath[1] == arrCurrentPath[1] && arrPath[2] == arrCurrentPath[2],
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        };
      });
    };

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs();
    });

    onMounted(() => {
      // console.clear();
      breadcrumbs.value = getBreadcrumbs();
    });
    return {
      breadcrumbs,
    };
  },
};
</script>
