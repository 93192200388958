// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'

import nav from '@/_nav.js'
import navSelected from '@/_navSelected.js'

import ChildrenLayout from '@/layouts/ChildrenLayout'
import store from '../store'
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    redirect: '/',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/status-doctor',
        name: 'Status Doctor',
        component: () => import('@/views/status_dokter/parts/List.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/',
        redirect: '/master/partner',
        name: 'Partner',
        component: ChildrenLayout,
        meta: { requiresAuth: true },
        children: [
          {
            path: '/master/partner',
            name: '',
            component: () => import('@/views/Partner.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/master/poly_doctor/:id_medical_facility/:id_poly',
            name: 'Poly Dokter',
            component: () => import('@/views/master/PolyDoctor.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/master/medicine/:id_medical_facility',
            name: 'Medicine Faskes',
            component: () => import('@/views/medicine/index.vue'),
            meta: { requiresAuth: true },
          },

          {
            path: '/master/polyclinic/:id_medical_facility',
            name: 'Poliklinik',
            component: () => import('@/views/polyclinic'),
            meta: { requiresAuth: true },
          },
        ]
      },
      {
        path: '/master/admin',
        name: 'Admin',
        component: () => import('@/views/master/Admin.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/voucher',
        name: 'Voucher',
        component: () => import('@/views/master/Voucher.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/user-voucher/:id_user',
        name: 'User Voucher',
        component: () => import('@/views/master/UserVoucher.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/doctor',
        redirect: '/master/doctor',
        name: 'Dokter',
        component: ChildrenLayout,

        meta: { requiresAuth: true },
        children: [
          {
            path: '/master/doctor',
            name: 'Dokter',
            component: () => import('@/views/master/Doctor.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/master/cu_doctor',
            name: 'Create Update Doctor',
            component: () => import('@/views/master/parts/AddDoctor.vue'),
            props: (route) => ({
              ...route.params
          }),
            meta: { requiresAuth: true, conditionalRoute: true },
          },
        ]
      },
      {
        path: '/master/user',
        name: 'Pasien',
        component: () => import('@/views/master/User.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/diseases',
        name: 'Penyakit',
        component: () => import('@/views/master/Diseases.vue'),
        meta: { requiresAuth: true },
      },

      {
        path: '/master/tindakan',
        name: 'Tindakan',
        component: () => import('@/views/master/Tindakan.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/med_tindakan',
        name: 'Medical Tindakan',
        component: () => import('@/views/treatment/index.vue'),
        meta: { requiresAuth: true },
      },

      {
        path: '/master/diseases-detail',
        name: 'Penyakit Detail',
        component: () => import('@/views/master/parts/DiseasesDetail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/article',
        name: 'Artikel',
        component: () => import('@/views/article/'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      // {
      //   path: '/master/wellingscode',
      //   name: 'Wellings Code',
      //   component: () => import('@/views/master/WellingsCode.vue'),
      //   meta: { requiresAuth: true },
      // },
      {
        path: '/master/role',
        name: 'Role',
        component: () => import('@/views/role'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/poly',
        name: 'Poly',
        component: () => import('@/views/master/Poly.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/medicine',
        name: 'Medicine',
        component: () => import('@/views/master/Medicine.vue'),
        meta: { requiresAuth: true },
      },

      {
        path: '/master/menu',
        name: 'Menu',
        component: () => import('@/views/menu/'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },

      {
        path: '/history/medicine-delivery',
        name: 'Antar Obat',
        component: () => import('@/views/medicine_delivery/'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/online-schedule',
        name: 'Jadwal Online',
        component: () => import('@/views/online_schedule/'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/online-doctor',
        name: 'Dokter Online',
        component: () => import('@/views/online_doctor/'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/online-doctor/detail/:id_detail',
        name: 'Dokter Online Detail',
        component: () => import('@/views/online_doctor/parts/Detail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/offline-doctor',
        name: 'Dokter Jaga Klinik',
        component: () => import('@/views/offline_doctor/'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/offline-doctor/detail/:id_detail',
        name: 'Dokter Jaga Klinik Detail',
        component: () => import('@/views/offline_doctor/parts/Detail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/online-doctor-free',
        name: 'Dokter Online Free',
        component: () => import('@/views/online_doctor_free/'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/history/online-doctor-free/detail/:id_detail',
        name: 'Dokter Online Detail Free',
        component: () => import('@/views/online_doctor_free/parts/Detail.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/cco',
        name: 'CCO',
        component: () => import('@/views/cco/'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/history/payment',
        name: 'Payment',
        component: () => import('@/views/payment'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/tnc',
        name: 'Terms and Condition',
        component: () => import('@/views/master/TNC.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/history/user',
        name: 'History Pasien',
        component: () => import('@/views/online_doctor/parts/HistoryUser.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/medicine',
        name: 'History Obat',
        component: () => import('@/views/online_doctor/parts/HistoryMedicine.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/diagnose',
        name: 'History Diagnosa',
        component: () => import('@/views/online_doctor/parts/HistoryDiagnose.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/report_diagnose/:id_diagnose',
        name: 'Report History Diagnosa',
        component: () => import('@/views/online_doctor/parts/ReportDiagnoseDetail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/report_diagnose/:medicine_code',
        name: 'Report History Obat',
        component: () => import('@/views/online_doctor/parts/ReportMedicineDetail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/report_pending',
        name: 'Report Pending',
        component: () => import('@/views/pending/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/history/refund',
        name: 'Refund',
        component: () => import('@/views/refund/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/icds',
        name: 'Diagnosa',
        component: () => import('@/views/master/Icds'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/user/family/:id_user',
        name: 'Family',
        component: () => import('@/views/master/Family'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/user/address/:id_user',
        name: 'Address',
        component: () => import('@/views/master/Address'),
        meta: { requiresAuth: true },
      },
      {
        path: '/master/banner',
        name: 'Banner',
        component: () => import('@/views/master/Banner.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/doctor-online/diagnose',
        name: 'DO Diagnose',
        component: () => import('@/views/online_doctor/parts/Diagnose'),
        meta: { requiresAuth: true },
      },
      {
        path: '/doctor-online/medicine',
        name: 'DO Medicine',
        component: () => import('@/views/online_doctor/parts/Medicine'),
        meta: { requiresAuth: true },
      },

      {
        path: '/doctor-online/chat',
        name: 'DO Chat',
        component: () => import('@/views/online_doctor/parts/Chat'),
        meta: { requiresAuth: true },
      },
      {
        path: '/consultation/list',
        name: 'Consultation List',
        component: () => import('@/views/consultation/parts/List'),
        meta: { requiresAuth: true },
      },
      {
        path: '/consultation/detail/:doctor_online_id',
        name: 'Consultation Detail',
        component: () => import('@/views/consultation/parts/Detail'),
        meta: { requiresAuth: true },
      },

      //log
      {
        path: '/logs/payment',
        name: 'Log Payment',
        component: () => import('@/views/logs/Payment'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/logs/delivery',
        name: 'Log Delivery',
        component: () => import('@/views/logs/Delivery'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/logs/stock-medicine',
        name: 'Log Stock Medicine',
        component: () => import('@/views/logs/StockMedicine'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/logs/price-medicine',
        name: 'Log Medicine Price',
        component: () => import('@/views/logs/MedicinePrice'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/logs/asset',
        name: 'Log Asset',
        component: () => import('@/views/logs/Asset'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/Profile'),
        meta: { requiresAuth: true },
      },
      {
        path: '/edit-profile',
        name: 'Edit Profile',
        component: () => import('@/views/profile/EditProfile'),
        meta: { requiresAuth: true },
      },
      {
        path: '/change-password',
        name: 'Change Password',
        component: () => import('@/views/authentication/ResetPassword'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/approval-record',
        name: 'Approval Record',
        component: () => import('@/views/medical_record/parts/Approval'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/input-record',
        name: 'Input Record',
        component: () => import('@/views/medical_record/parts/InputRecord'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/change-password',
        name: 'Change Password',
        component: () => import('@/views/authentication/ResetPassword'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      // {
      //   path: '/wellings/total-consultation',
      //   name: 'Total Consultation',
      //   component: () => import('@/views/wellings/TotalConsultation'),
      //   meta: { requiresAuth: true },
      // },
      {
        path: '/wellings/wellingscode',
        name: 'Wellings Code',
        component: () => import('@/views/wellings/WellingsCode.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/wellings/report',
        name: 'Wellings Report',
        component: () => import('@/views/wellings/index.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/wellings_dof/report',
        name: 'Wellings Report DOF',
        component: () => import('@/views/wellings_dof/index.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/clinic/report',
        name: 'Clinic Report',
        component: () => import('@/views/clinic_report/index.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/clinic/registration',
        name: 'Registrasi Patient',
        component: () => import('@/views/clinic/RegisterPatient.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/room',
        name: 'Room',
        component: () => import('@/views/master/Room.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },

      {
        path: '/master/asset/:id_room',
        name: 'Asset',
        component: () => import('@/views/master/Asset.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },

      {
        path: '/approval/approval-update-medical-record',
        name: 'Approval Update Medical Record',
        component: () => import('@/views/approval/approval-update-medical-record.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/master/insurer',
        name: 'Insurer',
        component: () => import('@/views/master/Insurer.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines',
        name: 'Medicine Dashboard',
        component: () => import('@/views/medicines/Dashboard.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines/order',
        name: 'Medicine Order',
        component: () => import('@/views/medicines/Order.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines/vendor',
        name: 'Vendor',
        component: () => import('@/views/medicines/Vendor.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines/retur',
        name: 'Retur',
        component: () => import('@/views/medicines/Retur.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      // {
      //   path: '/medicines/retur/process',
      //   name: 'Process Retur',
      //   component: () => import('@/views/medicines/ReturProcess.vue'),
      //   meta: { requiresAuth: true, conditionalRoute: true },
      // },
      {
        path: '/medicines/process',
        name: 'Medicine Order on Process',
        component: () => import('@/views/medicines/Process.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines/finish',
        name: 'Medicine Order Finish',
        component: () => import('@/views/medicines/Finish.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines/stock',
        name: 'Medicine Stock',
        component: () => import('@/views/medicines/Stock.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },
      {
        path: '/medicines/log',
        name: 'Medicine Log',
        component: () => import('@/views/medicines/Log.vue'),
        meta: { requiresAuth: true, conditionalRoute: true },
      },

    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/authentication/Login'),
  },
  {
    path: '/resetPassword',
    name: 'Reset Password',
    component: () => import('@/views/authentication/ResetPassword'),
  },
  {
    path: '/forgetPassword',
    name: 'Forget Password',
    component: () => import('@/views/authentication/ForgetPassword'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/pages/Page404'),
  },



]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // mode: 'history',
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  //console.log(to);
  const authenticatedUser = !!store.getters.isLoggedIn
  const filterNav = store.getters.getMedFac == 0 ? nav : navSelected;
  // //console.log("🚀 ~ file: index.js ~ line 306 ~ router.beforeEach ~ authenticatedUser", authenticatedUser, store.getters.isLoggedIn, store.getters.getAuth)
  // //console.log(authenticatedUser, store.state.auth, !store.getters.isLoggedIn)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const checkinRoute = filterNav.findIndex((m) => m.name == to.name);
  //console.log('checkinRoute', checkinRoute);
  const conditionalRoute = to.matched.some((record) => record.meta.conditionalRoute);

  if (requiresAuth && !authenticatedUser) next('login')
  else {
    // if(checkinRoute > -1){
    // next()
    // }
    // if (store.getters.getMedfac > 0) {
    //   if(conditionalRoute > -1 && conditionalRoute){
    //     next()
    //   }
    //   next('404')
    // }
    next();
  }
})

export default router
