import { GetJSON, PostJSON, PutJSON, DeleteJSON } from "../helper/Network/";
import HttpRequest from "../helper/Network/HttpRequest";
import { resolveGET, resolvePOST, resolvePUT, resolveDELETE, setNumber } from './Common'
global.SDHttpRequest = HttpRequest
global.SDHttpGetJSON = GetJSON
global.SDHttpPostJSON = PostJSON
global.SDHttpPutJSON = PutJSON
global.SDHttpDeleteJSON = DeleteJSON
global.SDResolvePOST = resolvePOST
global.SDResolveGET = resolveGET
global.SDResolvePUT = resolvePUT
global.SDPaginateNumber = setNumber

global.SDResolveDELETE = resolveDELETE
