<template>
  <div
    :style="{ flexDirection: 'row' }"
    class="profileButton"
    @click="profile"
    variant="nav-item"
  >
    <CImage :src="avatar" />
  </div>
</template>

<script>
import doctorOnline from '../../public/doctor online.png'
import router from '../router'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: doctorOnline,
      itemsCount: 42,
    }
  },
  methods: {
    profile() {
      router.push({name: 'Profile'})
    },
  },
}
</script>
