import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import { useStore } from 'vuex'
import Common from '@/config/Common'
import yukFit from '@/assets/yukFitTheme'
// config is optional. more below

// import '../OneSignalSDKWorker'
{
  /* <script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async=""></script> */
}
const myMixin = {
  computed: {
    store: () => useStore(),
    common: () => Common,
    language: () => useStore().getters.language_strings,
    yfTheme: () => yukFit,
  },
  methods: {
    dateFormat(a) {
      // const months = [
      //   'January',
      //   'February',
      //   'March',
      //   'April',
      //   'May',
      //   'June',
      //   'July',
      //   'August',
      //   'September',
      //   'October',
      //   'November',
      //   'December',
      // ]
      return `${a.getDate()}/${a.getMonth() + 1}/${a.getFullYear()}`
    },
  },
}

//SERVER
// const ServerURL = 'http://staging.yukfit.id:8082/v1/'
SDHttpRequest.config({
  DefaultHeaders: async () => {
    // let authorization = {};
    if (localStorage.getItem('token') !== null) {
      let authorization = {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
      return {
        ...{
          'Content-Type': 'application/json',
        },
        ...authorization,
      }
    } else {
      return {
        'Content-Type': 'application/json',
      }
    }
  },
})

// //console.log(`test`,Common.ONESIGNAL_APP_ID)
const app = createApp(App)
app.use(store)
app.use(OneSignalVuePlugin, {
  appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
  notifyButton: {
    enable: true,
  },
})
// this.$oneSigna
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { VueSignaturePad } from 'vue-signature-pad'
import JsonExcel from "vue-json-excel3";


app.component('VueSignaturePad', VueSignaturePad)
app.config.productionTip = false

app.component('v-select', vSelect)

app.mixin(myMixin)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Datepicker', Datepicker)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component("downloadExcel", JsonExcel);

app.mount('#app')
