let HttpConfig = {
	BaseURL: '',
	DefaultHeaders: async () => ({})
};

const MapToQueryString = (body) => {
	return Object.keys(body)
		.map(
			(k) => `${k}=${encodeURI(body[k])}`
		)
		.join('&')
};

const AbortSupported = (typeof AbortController !== 'undefined');

const HttpRequest = function (path, httpMethod) {
	httpMethod = `${httpMethod}`.trim().toUpperCase() || 'GET';
	const willSendBody = ['POST', 'PUT'].indexOf(httpMethod) >= 0;

	const abortController = (AbortSupported) ? new AbortController() : undefined;

	let url = HttpConfig.BaseURL;
	let requestHeaders = {};
	let isSent = false;
	let isAborted = false;
	let httpResponse = null;
	let fetchResultOnAbort = {requestAborted: true}; // on aborted request, fetch will reject with this value

	/**
	 * Replace current url to a different url
	 *
	 * @param newUrl String
	 * @returns {HttpRequest}
	 */
	this.replaceUrl = function (newUrl) {
		if (typeof newUrl === 'string') {
			url = newUrl;
		}
		return this;
	}

	/**
	 * Set a http header to this request
	 *
	 * @param key String
	 * @param value Scalar value
	 * @returns {HttpRequest}
	 */
	this.setHeader = function (key, value) {
		return this.addHeaders({[key]: value});
	};

	/**
	 * add or set multiple headers to this request
	 *
	 * @param headers Object containing { String: Scalar value }
	 * @returns {HttpRequest}
	 */
	this.addHeaders = function (headers) {
		requestHeaders = {...requestHeaders, ...headers};
		return this;
	};

	/**
	 * Abort the request
	 * this depends on AbortController to be exists,
	 * if not, only simulate the abortion by rejecting the result-promise
	 *
	 * @param callback
	 */
	this.abort = function (fetchResult) {
		isAborted = true;
		if (typeof fetchResult !== 'undefined') {
			fetchResultOnAbort = fetchResult;
		}
		if (abortController) {
			abortController.abort();
		}
	};

	/**
	 * Get the http response from the last call, if any
	 *
	 * @returns {Response}
	 */
	this.getLastResponse = function () {
		return httpResponse;
	};

	/**
	 * performs and send Http Request with body to be sent
	 *
	 * @param body Any applicable type to be sent as http parameter,usually String or File instance
	 * @returns {Promise<Response>}
	 */
	this.send = async function (body, GPSPriority) {
		isSent = true;

		const requestInit = {
			method: httpMethod,
			// mode:'no-cors',
			headers: {...(await HttpConfig.DefaultHeaders()), ...requestHeaders}
		};		

		if (abortController && ('signal' in abortController)) {
			requestInit.signal = abortController.signal;
		}
		let targetUrl = `${url}${path}`;
		if (body) {
			if (willSendBody) {
				requestInit.body = body;
			} else {
				// use as query string
				const queryStringExists = targetUrl.indexOf('?') > 0;
				targetUrl = targetUrl
					+ (queryStringExists ? '' : '?')
					+ body;
			}
		}

		//console.log('Send Request to: ' + targetUrl);
		//console.log('Send Request with initialization', requestInit);
		//console.log('Body parameter(s)', body);
		return new Promise(function (resolve, reject) {
			if (isAborted) {
				return reject(fetchResultOnAbort)
			}
			fetch(targetUrl, requestInit)
				.then((response) => {
					if (isAborted) {
						httpResponse = null;
						return reject(fetchResultOnAbort);
					} else {
						// store response
						// later can be retrieved with .getLastResponse()
						httpResponse = response;
						return resolve(response);
					}
				})
				.catch((error) => {
					// request is aborted
					if ('name' in error && error.name === 'AbortError' && isAborted) {
						return reject(fetchResultOnAbort);
					} else {
						return reject(error);
					}
				});
		});
	};
};

/**
 * set global configuration for each HttpRequest instance
 *
 * @param config Object as
 * {
 *      BaseURL: String,
 *      DefaultHeaders: async function returns { String: scalar }
 * }
 */
HttpRequest.config = function (config) {
	HttpConfig = {...HttpConfig, ...config};
};
export {MapToQueryString};
export default HttpRequest;
