<template>
  <router-view v-if="isReady" />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<script>
import './config/GlobalFunction'
import VueJwtDecode from 'vue-jwt-decode'
import router from './router'
import Services from './api/Services'

export default {
  data() {
    return {
      userId: null,
      isReady: false,
    }
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt()
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      const check = this.$store.getters.authId
      //console.log(check);

      if (check === null) {
        router.push({ name: 'Login' })
      }
    },
    async refreshToken() {
      // //console.log('test')

      if (localStorage.getItem('token')) {
        const decode = VueJwtDecode.decode(localStorage.getItem('token'))
        //console.log(Date.now(), decode.exp * 1000);

        if (Date.now() >= decode.exp * 1000) {
          //console.log("expired");
          this.logout()
        } else {
          //console.log("not expired");
          await Services.refresh_token()
            .then((res) => {
              // //console.log(
              //   "🚀 ~ file: App.vue ~ line 38 ~ Services.refresh_token ~ res",
              //   res
              // );

              if (res.status == 'success') {
                localStorage.setItem('token', res.data.access_token)
              } else {
                this.logout()
              }
            })
            .finally(() => {
              this.isReady = true
            })
        }
      } else {
        this.isReady = true
      }
    },
    async gettingUserId() {
      // //console.log(
      //   "🚀 ~ file: App.vue ~ line 70 ~ gettingUserId ~ this.userId",
      //   this.userId
      // );
      if (this.userId == null) {
        this.$OneSignal.getUserId().then((res) => {
          // //console.log(
          //   "🚀 ~ file: App.vue ~ line 74 ~ this.$OneSignal.getUserId ~ res",
          //   res
          // );
          this.userId = res
          localStorage.setItem('onesignal', res)
        })
        setTimeout(this.gettingUserId, 3000)
        return
      }
    },
  },
  async mounted() {
    await this.refreshToken()
    // //console.log('onesignal', this.$OneSignal)
    // this.$OneSignal.on('notificationPermissionChange', function (isSubscribed) {
    //   //console.log("The user's subscription state is now:", isSubscribed)
    // })

    // this.$OneSignal.setSubscription(true)
    this.gettingUserId()
  },
}
</script>
