import Helper from '@/assets/Helper'
const derive = Helper.all;
import Common from '@/config/Common'

const yellow = Common.DEFAULT.COLOR.YELLOW
const white = Common.DEFAULT.COLOR.WHITE
const blue = Common.DEFAULT.COLOR.BLUE
const dark_blue = Common.DEFAULT.COLOR.DARK_BLUE
const dark_grey = Common.DEFAULT.COLOR.DARK_GREY
const red = Common.DEFAULT.COLOR.RED
const green = Common.DEFAULT.COLOR.GREEN
const title_font_size = 24 + 'px'
const body_font_size = 16 + 'px'
const small_font_size = 12 + 'px'

const yukFit = {
  basic_border: {
    borderRadius: 15 + 'px'
  },
  font_blue: {
    color: blue
  },
  font_white: {
    color: white
  },
  font_dark_blue: {
    color: dark_blue
  },
  font_grey: {
    color: dark_grey
  },
  font_yellow: {
    color: yellow
  },
  smallTextTitle: {
  },
  baseTextTitle: {
    fontSize: title_font_size,
  },
  baseTextBody: {
    fontSize: body_font_size,
  },
  base_button: {
    borderRadius: 10 + 'px',
    color: white,
  },
  bold_text: {
    font_weight: 'normal'
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'horizontal'
  },
  get white_button(){
    return derive(this.base_button, {
      backgroundColor: white,
      color: 'black'
    })
  },
  get yellow_button() {
    return derive(this.base_button, {
      backgroundColor: yellow,
    })
  },
  get green_button() {
    return derive(this.base_button, {
      backgroundColor: green,
    })
  },
  get blue_button() {
    return derive(this.base_button, {
      backgroundColor: blue
    })
  },
  get grey_button() {
    return derive(this.base_button, {
      backgroundColor: dark_grey
    })
  },
  get red_button() {
    return derive(this.base_button, {
      backgroundColor: red
    })
  },
  get yellow_button_new() {
    return derive(this.base_button, {
      backgroundColor: yellow,
      minWidth: 120+'px',
      marginRight: 10+'px'
    })
  },
  get blue_button_new() {
    return derive(this.base_button, {
      backgroundColor: blue,
      minWidth: 120+'px',
      marginRight: 10+'px'
    })
  },
}

export default yukFit

