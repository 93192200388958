//intibios
const userAdmin = [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Status Doctor',
    to: '/status-doctor',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavGroup',
    name: 'Master',
    to: '/master',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Mitra',
        to: '/master/partner',
      },
      {
        component: 'CNavItem',
        name: 'Dokter',
        to: '/master/doctor',
      },
      {
        component: 'CNavItem',
        name: 'Medicine',
        to: '/master/medicine',
      },

      {
        component: 'CNavItem',
        name: 'Diseases',
        to: '/master/diseases',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Wellings Code',
      //   to: '/master/wellingscode'
      // },
      // {
      //   component: 'CNavItem',
      //   name: 'Role',
      //   to: '/master/role',
      // },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Report',
    to: '/history',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Doctor Offline',
        to: '/history/offline-doctor',
      },
      {
        component: 'CNavItem',
        name: 'Pembayaran',
        to: '/history/payment',
      },
      {
        component: 'CNavItem',
        name: 'Penyakit',
        to: '/history/diagnose',
      },
      {
        component: 'CNavItem',
        name: 'Obat',
        to: '/history/medicine',
      },
      {
        component: 'CNavItem',
        name: 'Search',
        to: '/consultation/list',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Medical Record',
    to: '/medical-record',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: 'Approval',
        to: '/approval-record',
      },
      {
        component: 'CNavItem',
        name: 'Input',
        to: '/input-record',
      },
    ],
  },
]

export default userAdmin
