import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import * as mutations from './mutations'
import getStringByLanguage from "@/config/language";


const vuexLocal = new VuexPersistence({
  strictMode: false,
  storage: window.localStorage,
})
// eslint-disable-next-line no-import-assign
Object.assign(mutations, {
  RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
})

const vuexPersistEmitter = () => {
  return (store) => {
    store.subscribe((mutation) => {
      if (mutation.type === 'RESTORE_MUTATION') {
        //console.log('Storage is ready to use!')
        store.commit('setDataReady')
        store._vm.$root.$emit('storageReady')
      }
    })
  }
}

export default createStore({
  plugins: [vuexLocal.plugin, vuexPersistEmitter()],
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    auth: '',
    medicalFacility: 0,
    activeLanguage: 'id',
    profile: {}
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getAuth(state) {
      return state.auth
    },
    isLoggedIn(state) {
      return !!state.auth && state.auth !== ''
    },
    authId(state) {
      return state.auth
    },
    current_lang: state => {
      return state.activeLanguage;
    },
    language_strings: state => {
      return getStringByLanguage(state.activeLanguage);
    },
    getProfile: state => {
      return state.profile
    },
    getMedfac(state) {
      return state.medicalFacility
    }
  },
  mutations,
  actions: {
    login({ commit }, id) {

      // const decode = VueJwtDecode.decode(localStorage.getItem('token'));
      // //console.log("🚀 ~ file: index.js ~ line 62 ~ login ~ decode", id)
      // commit('setProfile', decode)
      commit('updateAuth', id)
    },
    setMedfac({ commit }, id) {

      // const decode = VueJwtDecode.decode(localStorage.getItem('token'));
      // //console.log("🚀 ~ file: index.js ~ line 62 ~ login ~ decode", id)
      // commit('setProfile', decode)
      commit('updateMedfac', id)
    },
    logout({ commit }) {
      commit('updateAuth', null)
      localStorage.removeItem('token')
    },
    updateProfile({ commit }, profile) {
      commit('setProfile', profile)
    },
    updateLang({ commit }, data) {
      commit('changeLanguage', data)
    },
  },
  modules: {},
})
